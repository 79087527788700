/* eslint-disable */
import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import AppReducers from "./reducers";
import Routes from "./routers";
import ReactGA from "react-ga";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import RB2BScript from "./RB2BScript";

// create redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  AppReducers,
  undefined,
  composeEnhancers(applyMiddleware(thunk))
);

// recaptcha key
const recaptchaSiteKey = "6LfuKbYZAAAAAEsVlf5mpJ4qfTyo0XukOu7uCuK4";

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-173221846-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // RB2B
    this.rb2bScript();
  }

  rb2bScript() {
    !(function () {
      var reb2b = (window.reb2b = window.reb2b || []);
      if (reb2b.invoked) return;
      reb2b.invoked = true;
      reb2b.methods = ["identify", "collect"];
      reb2b.factory = function (method) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          reb2b.push(args);
          return reb2b;
        };
      };
      for (var i = 0; i < reb2b.methods.length; i++) {
        var key = reb2b.methods[i];
        reb2b[key] = reb2b.factory(key);
      }
      reb2b.load = function (key) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" +
          key +
          "/reb2b.js.gz";
        var first = document.getElementsByTagName("script")[0];
        first.parentNode.insertBefore(script, first);
      };
      reb2b.SNIPPET_VERSION = "1.0.1";
      reb2b.load("ZQOQRJH35J62");
    })();
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <GoogleReCaptchaProvider
            reCaptchaKey={recaptchaSiteKey}
            language={"en"}
            useRecaptchaNet={false}
          >
            <Routes />
          </GoogleReCaptchaProvider>
        </Provider>
        {/* <RB2BScript /> */}
      </>
    );
  }
}

export default App;
